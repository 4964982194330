<template>
    <v-card width="100%" height="100%" class="rounded-0">
        <!-- <v-card-title>Incident Control Panel</v-card-title> -->
        <v-toolbar
            color="biscuit"
            class="justify-center">
            <v-toolbar-title>Incident Control Panel</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <p>
                Entrycode: <span class="font-weight-black">{{ getIncident.entrycode}}</span>
            </p>
            <p>
                ID: <span class="font-weight-black">{{ getIncident.id }}</span>
            </p>
            <p justify="right">
                <v-btn @click.stop="gotoViewMode" color="guacamole">View Incident <v-icon>mdi-eye</v-icon></v-btn>
            </p>
            <v-divider class="mb-2" />
            <p>
                Created on: <span class="font-weight-black">{{ dateformat(getIncident.created) }}</span>
            </p>
            <p>
                By: <span class="font-weight-black">{{ getIncident.author }}</span>
            </p>
            <v-divider class="mb-2" />
            <p>
                Modified: <span class="font-weight-black">{{ dateformat(getIncident.modified) }}</span>
            </p>
            <p>
                By: <span class="font-weight-black">{{ getIncident.modifiedby }}</span>
            </p>
            <v-divider class="mb-2" />
            <p>
                Version: <span class="font-weight-black">{{ getIncident.data.version }}</span>
            </p>
            <v-divider class="mb-2" />
            <p class="mt-3">
                <!-- Live event: <span class="font-weight-black">{{ liveevent }}</span> -->
                Live event: <v-chip :color="livecolor">{{ liveevent }}</v-chip>
            </p>
        </v-card-text>

        <v-card-actions>
            <v-spacer />
            <v-btn color="biscuit" @click.prevent="toggleLive">
                Toggle Live
                <v-progress-circular color="guacamole" v-if="loading" indeterminate/>
            </v-btn>
            <!-- <v-btn color="guacamole" @click.stop="editIncident">
                Edit
            </v-btn> -->
        </v-card-actions>
    </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import { DateTime } from 'luxon'

export default {
    name: "IncidentInfo",
    data: () => ({
        loading: false
    }),
    computed: {
        ...mapGetters({
            getIncident: "incidentsnew/getIncident"
        }),
        liveevent () {
            return (this.getIncident.live ? "Yes" : "No")
        },
        livecolor () {
            return (this.getIncident.live ? "guacamole" : "red")
        }
    },
    methods: {
        // editIncident () {
        //     this.$router.push({name: "InformantTestForm", params: {entrycode: this.getIncident.entrycode}})
        // },
        dateformat (timestamp) {
            if (timestamp) {
                let _timestamp = DateTime.fromISO(timestamp).toFormat("dd/MM/yyyy HH:mm:ss")
                return _timestamp
            }
        },
        toggleLive () {
            this.loading = true
            let live = this.getIncident.live ? false : true
            console.log(live)
            this.$store.dispatch('incidentsnew/toggleLive', {
                'entrycode': this.getIncident.entrycode,
                'live': live
            }).then(this.loading=false)
        },
        gotoViewMode() {
            this.$router.push({name: "ViewIncident", params: {entrycode: this.getIncident.entrycode}})
        }
    }
}
</script>
